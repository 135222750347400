import React from 'react'

import SEO from 'components/seo'

export default function FAQPage(props) {
  return (
    <>
      <SEO
        title="FAQ"
        description="Do you have any questions about our solutions? Get your answers here!"
      />
      <div>FAQ</div>
    </>
  )
}
